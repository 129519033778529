import axios from '@axios';

function combineTokenSum(...arrays) {
  let result = [];

  // 合并所有数组
  arrays.forEach(arr => {
    arr.forEach(item => {
      const target = result.find(i => i.name === item.name);
      if (target) {
        target.sum += item.sum; // 如果存在相同名称，累加 sum
      } else {
        result.push({ ...item }); // 否则，添加新的对象
      }
    });
  });

  // 映射到新格式
  result = result.map(item => ({
    label: item.name,
    value: item.sum,
  }));

  // 排序并返回前 10 个
  return result.sort((a, b) => b.value - a.value).slice(0, 10);
}
export default {
  namespaced: true,
  state: {

  },
  getters: {},
  mutations: {},
  actions: {
    fetchOverviewData(ctx) {
      // {
      //   "enterprise_num": "47",  企业数
      //   "user_num": "13584", 用户数
      //   "app_num": "138", 应用数
      //   "document_num": "363", 文档数
      //   "faq_num": "132", faq 文件数
      //   "link_num": "6", // 链接数
      //   "knowledge_num": "501", 知识库内容数
      //   "document_total_size": 265065601, 文档总大小，单位字节
      //   "faq_total_size": 4058188 faq文件总大小，单位字节
      // }
      return new Promise((resolve, reject) => {
        axios
          .get('/dataService/dashboard/overview')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsageData(ctx, { startDate, endDate }) {
      // {
      //   "talk_num": "2376", 出话次数
      //   "faq_talk_num": "89", // 各渠道出话数：faq次数
      //   "model_talk_num": "2328", // 各渠道出话数：model次数
      //   "token_total": "364010", token总数
      //   "model_tokens_list": [
      //       {
      //           "model_name": "Minimax", 模型名称
      //           "tokens": "71737", token数
      //           "model_id": "minimax"
      //       },
      //   ]
      // }
      return new Promise((resolve, reject) => {
        axios
          .get('/api/customize/accountCountAndAppCountAndTokenCost', {
            params: {
              start_time: startDate,
              end_time: endDate,
            },
          })
          .then(res => {
            console.log('1233', res)
            const response = res.data
            const result = {
              talk_num: response.tokens_dialogue.reduce((acc, cur) => acc + cur.sum, 0),
              tokens_agent_chat_dialogue_result: response.tokens_agent_chat_dialogue_result.reduce(
                (acc, cur) => acc + cur.sum,
                0,
              ),
              tokens_completion_dialogue_result: response.tokens_completion_dialogue_result.reduce(
                (acc, cur) => acc + cur.sum,
                0,
              ),
              faq_talk_num: '44',
              model_talk_num: '363',
              token_total: response.tokens_workflow.reduce((acc, cur) => acc + cur.sum, 0),
              model_tokens_list: [],

              app_num: response.app_count.reduce((acc, cur) => acc + cur.count, 0),
              user_num: response.user_count[0].count,
              enterprise_num: response.user_count[0].count
                ? (response.app_count.reduce((acc, cur) => acc + cur.count, 0) / response.user_count[0].count).toFixed(
                  2,
                )
                : 0,
              tokenRank: combineTokenSum(response.tokens_workflow, response.tokens_dialogue, response.tokens_agent_chat_dialogue_result, response.tokens_completion_dialogue_result),
              robotRank: response.app_count
                .sort((a, b) => b.count - a.count)
                .splice(0, 10)
                .map(item => ({ label: item.name, value: item.count })),
            }
            resolve(result)
          })
          .catch(error => reject(error))
      })
    },
  },
}
