<template>
  <v-card>
    <v-card-title
      class="align-start"
    >
      <span class="font-weight-semibold">Tokens使用数据</span>

      <v-spacer></v-spacer>

      <!-- <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon class="cursor-pointer">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn> -->
      <div
        class="d-flex"
        style="align-items:center;position: relative;top: -10px;"
      >
        <v-menu
          v-model="isStartDateMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          eager
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="queryParams.start"
              label="开始日期"
              readonly
              outlined
              dense
              hide-details="auto"
              :loading="props.loading"
              v-bind="attrs"
              style="width: 111px;"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="queryParams.start"
            @input="isStartDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>
        <div style="margin:0 10px;">
          -
        </div>
        <v-menu
          v-model="isEndDateMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          eager
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="queryParams.end"
              label="结束日期"
              readonly
              outlined
              size=""
              dense
              hide-details="auto"
              :loading="props.loading"
              v-bind="attrs"
              style="width: 111px;"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="queryParams.end"
            @input="isEndDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </v-card-title>

    <!-- <v-card-subtitle class="mb-7">
      <span class="font-weight-semibold text--primary me-1">Total 48.5% growth</span>
      <span>😎 this month</span>
    </v-card-subtitle> -->
    <v-card-subtitle class="mb-2"></v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          sm="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { formatNumber } from '@core/utils';
import {
  mdiCalendarBlank, mdiCalendarBlankOutline, mdiDotsVertical,
  mdiFaceAgent,
  mdiMessageOutline,
  mdiSitemap,
  mdiTextBoxCheckOutline,
} from '@mdi/js';
import { computed, ref, watch } from '@vue/composition-api';

export default {
  model: {
    prop: 'params',
    event: 'update:params',
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
      default: () => ({
        talk_num: 0, // 聊天助手
        tokens_agent_chat_dialogue_result: 0, // agentTokens
        tokens_completion_dialogue_result: 0, // 文本生成应用Tokens
        faq_talk_num: 0, // 各渠道出话数：faq次数
        model_talk_num: 0, // 各渠道出话数：model次数
        token_total: 0, // token总数
        model_tokens_list: [],
      }),
    },
    params: {
      type: Object,
      required: true,
      default: () => ({
        start: null,
        end: null,
      }),
    },
  },
  setup(props, { emit }) {
    const statisticsData = computed(() => [
      {
        title: '聊天助手',
        total: formatNumber(props.options.talk_num || '0'),
      },
      {
        title: '文本生成应用',
        total: formatNumber(props.options.tokens_completion_dialogue_result || '0'),
      },
      {
        title: 'Agent',
        total: formatNumber(props.options.tokens_agent_chat_dialogue_result || '0'),
      },
      {
        title: '工作流',
        total: formatNumber(props.options.token_total || '0'),
      },
      {
        title: '总Tokens',

        // total: formatNumber((props.options.token_total && props.options.talk_num) ? (Math.ceil(props.options.token_total / props.options.talk_num)) : 0),
        total: formatNumber((props.options.talk_num || 0) + (props.options.token_total || 0) + (props.options.tokens_completion_dialogue_result || 0) + (props.options.tokens_agent_chat_dialogue_result || 0)),
      },
    ])

    const resolveStatisticsIconVariation = data => {
      if (data === '聊天助手') return { icon: mdiMessageOutline, color: 'primary' }
      if (data === '文本生成应用') return { icon: mdiTextBoxCheckOutline, color: 'accent' }
      if (data === 'Agent') return { icon: mdiFaceAgent, color: 'error' }
      if (data === '工作流') return { icon: mdiSitemap, color: 'success' }
      if (data === '总Tokens') return { icon: mdiCalendarBlankOutline, color: 'warning' }

      return { icon: mdiCalendarBlank, color: 'success' }
    }

    const isStartDateMenuOpen = ref(false)
    const isEndDateMenuOpen = ref(false)
    const queryParams = ref({
      start: props.params.start,
      end: props.params.end,
    })
    watch(() => queryParams.value.start, val => {
      const obj = { ...queryParams.value }
      emit('update:params', obj)
    })
    watch(() => queryParams.value.end, val => {
      const obj = { ...queryParams.value }
      emit('update:params', obj)
    })

    return {
      props,
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
      },
      isStartDateMenuOpen,
      isEndDateMenuOpen,
      queryParams,
    }
  },
}
</script>
