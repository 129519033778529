<template>
  <div>
    <v-row>
      <v-col
        cols="24"
        sm="12"
        order="1"
        order-md="1"
      >
        <crm-statistics-card2
          v-model="queryParams"
          :loading="dataLoading"
          :options="usageData"
        ></crm-statistics-card2>
        <crm-statistics-card
          :options="overviewData"
          class="mt-3"
        ></crm-statistics-card>
      </v-col>

      <!-- <v-col
        cols="12"
        md="4"
        sm="12"
        order="2"
        order-md="2"
      >
        <ecommerce-card-website-statistics :options="overviewData"></ecommerce-card-website-statistics>
      </v-col> -->
      <v-col
        :key="1"
        cols="12"
        md="6"
        sm="12"
        order="6"
      >
        <crm-weekly-sales
          :key="1"
          title="Tokens使用排行榜"
          :options="tokenRankData"
        ></crm-weekly-sales>
      </v-col>
      <v-col
        :key="2"
        cols="12"
        md="6"
        sm="12"
        order="6"
      >
        <crm-weekly-sales
          :key="2"
          title="应用数量排行榜"
          :options="robotRankData"
        ></crm-weekly-sales>
      </v-col>

      <!-- <v-col
        cols="12"
        md="12"
        sm="12"
        order="6"
      >
        <analytics-card-sales-by-countries :options="usageData"></analytics-card-sales-by-countries>
      </v-col> -->
      <!-- <v-col
        cols="12"
        md="6"
        sm="12"
        order="6"
      >
        <crm-sales-overview :options="usageData"></crm-sales-overview>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import { dateFormat } from '@core/utils'
import { onUnmounted, ref, watch } from '@vue/composition-api'

// icons
import { mdiLabelVariantOutline } from '@mdi/js'

// demos
import AnalyticsCardSalesByCountries from './AnalyticsCardSalesByCountries.vue'
import CrmSalesOverview from './CrmSalesOverview.vue'
import CrmStatisticsCard from './CrmStatisticsCard.vue'
import CrmStatisticsCard2 from './CrmStatisticsCard2.vue'
import CrmWeeklySales from './CrmWeeklySales.vue'
import EcommerceCardWebsiteStatistics from './EcommerceCardWebsiteStatistics.vue'

import storeModule from './modelStoreModule'

export default {
  components: {
    CrmSalesOverview,
    CrmStatisticsCard,
    CrmStatisticsCard2,
    EcommerceCardWebsiteStatistics,
    AnalyticsCardSalesByCountries,
    CrmWeeklySales,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'dashboard-llm'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const overviewData = ref({})

    // store.dispatch('dashboard-llm/fetchOverviewData', {
    // }).then(response => {
    //   overviewData.value = response.data
    // })

    const usageData = ref({})
    const tokenRankData = ref({
      data: [],
    })
    const robotRankData = ref({
      data: [],
    })

    const queryParams = ref({
      start: dateFormat(+new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'),
      end: dateFormat(+new Date(), 'yyyy-MM-dd'),
    })

    const dataLoading = ref(false)
    function loadData() {
      dataLoading.value = true
      store.dispatch('dashboard-llm/fetchUsageData', {
        startDate: `${queryParams.value.start} 00:00:00`,
        endDate: `${queryParams.value.end} 23:59:59`,
      }).then(response => {
        usageData.value = response
        console.log('combineTokenSum', response.data)

        tokenRankData.value = {
          data: response.tokenRank,
        }
        console.log('1231234', response.robotRank)
        robotRankData.value = {
          data: response.robotRank,
        }

        overviewData.value = {
          enterprise_num: response.enterprise_num,
          user_num: response.user_num,
          app_num: response.app_num,
        }
      }).finally(() => {
        dataLoading.value = false
      })
    }
    loadData()
    watch(() => queryParams, () => {
      loadData()
    }, {
      deep: true,
    })

    // card with images
    const ratingsOptions = {
      statTitle: 'Ratings',
      statistics: '13k',
      change: '+38%',
      chipText: 'Year of 2021',
      chipColor: 'primary',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
    }
    const sessionsOptions = {
      statTitle: 'Sessions',
      statistics: '24.5k',
      change: '-22%',
      chipText: 'Last Week',
      chipColor: 'secondary',
      avatar: require('@/assets/images/avatars/10.png'),
      avatarWidth: '86',
    }

    const growthAreaChart = {
      statTitle: 'Total Growth',
      statistics: '42.5k',
      series: [
        {
          name: 'Subscribers',
          data: [28, 40, 36, 52, 38, 60, 55],
        },
      ],
    }

    // vertical card options
    const newProjectOptions = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    return {
      overviewData,
      usageData,
      tokenRankData,
      robotRankData,
      queryParams,
      dataLoading,

      ratingsOptions,
      sessionsOptions,
      growthAreaChart,
      newProjectOptions,
    }
  },
}
</script>
