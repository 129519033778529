<template>
  <v-card>
    <v-card-title class="align-start">
      <span>各模型平均Token数</span>
      <v-spacer></v-spacer>
      <!-- <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn> -->
    </v-card-title>

    <v-card-text>
      <v-row style="height: 406px; overflow: hidden;">
        <v-col
          cols="12"
          md="5"
          sm="12"
          order="1"
          order-md="1"
          style="height: 100%;"
        >
          <v-list
            class="pb-0"
            height="406"
          >
            <v-list-item
              v-for="(data,index) in modelTokensList"
              :key="data.model_id"
              :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4':''}`"
            >
              <v-avatar
                :color="data.color"
                size="40"
                :class="`v-avatar-light-bg ${data.color}--text font-weight-medium me-3`"
              >
                <span class="text-base">{{ data.model_name.charAt(0) }}</span>
              </v-avatar>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-2">
                  <div class="font-weight-semibold">
                    <span class="text--primary text-base me-1">{{ data.model_name }}</span>

                    <!-- <v-icon
                      size="20"
                      :color="data.change.charAt(0) === '+' ? 'success':'error'"
                    >
                      {{ data.change.charAt(0) === '+' ? icons.mdiChevronUp: icons.mdiChevronDown }}
                    </v-icon> -->

                    <!-- <span :class="`text-xs ${data.change.charAt(0) === '+' ? 'success--text':'error--text'}`">{{ data.change.slice(1) }}</span> -->
                  </div>

                  <v-list-item-subtitle
                    class="text-xs"
                    :title="data.model_id"
                    style="max-width: 200px;overflow: hidden;text-overflow: ellipsis;"
                  >
                    {{ data.model_id }}
                  </v-list-item-subtitle>
                </div>

                <v-spacer></v-spacer>

                <div>
                  <h4 class="font-weight-semibold">
                    {{ data.tokens }}
                  </h4>
                  <span class="text-xs">Tokens</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col
          cols="12"
          md="7"
          sm="12"
          order="4"
          order-md="4"
        >
          <vue-apex-charts
            height="406"
            :options="chartOptions"
            :series="chartData"
          ></vue-apex-charts>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { addAlpha, getVuetify } from '@core/utils';
import { mdiChevronDown, mdiChevronUp, mdiDotsVertical } from '@mdi/js';
import { computed } from '@vue/composition-api';

export default {
  components: { VueApexCharts: () => import('vue-apexcharts') },
  props: {
    options: {
      type: Object,
      required: true,
      default() {
        return {
          talk_num: 0, // 出话次数
          faq_talk_num: 0, // 各渠道出话数：faq次数
          model_talk_num: 0, // 各渠道出话数：model次数
          token_total: 0, // token总数
          model_tokens_list: [
            // {
            //     "model_name": "Minimax", 模型名称
            //     "tokens": "71737", token数
            //     "model_id": "minimax"
            // },
          ],
        }
      },
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'error']
    const modelTokensList = computed(() => {
      console.log('123', props.options.model_tokens_list)

      const arr = [...(props.options.model_tokens_list || [])]
      arr.sort((a, b) => (+b.tokens) - (+a.tokens))
      arr.forEach((item, key) => {
        // eslint-disable-next-line no-param-reassign
        item.color = colors[key % colors.length]
        if (item.model_name.indexOf('(推荐)') !== -1) {
          // eslint-disable-next-line no-param-reassign
          item.model_name = item.model_name.substring(0, item.model_name.indexOf('(推荐)'))
        }
      })
      console.log('123', arr)

      return arr
    })
    const chartOptions = computed(() => ({
      colors: [
        // $vuetify.theme.currentTheme.primary,
        addAlpha($vuetify.theme.currentTheme.primary, 0.4),
      ],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
          distributed: true,
          borderRadius: 4,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: modelTokensList.value.map(item => item.model_name),
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          left: -10,
          right: -10,
        },
      },
    }))

    const chartData = computed(() => ([
      {
        data: modelTokensList.value.map(item => +item.tokens),
      },
    ]))

    return {
      modelTokensList,

      chartOptions,
      chartData,
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>
