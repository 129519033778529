<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">应用数据总览</span>

      <v-spacer></v-spacer>

      <!-- <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon class="cursor-pointer">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn> -->
    </v-card-title>

    <!-- <v-card-subtitle class="mb-7">
      <span class="font-weight-semibold text--primary me-1">Total 48.5% growth</span>
      <span>😎 this month</span>
    </v-card-subtitle> -->
    <v-card-subtitle class="mb-2"></v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          sm="4"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { formatNumber } from '@core/utils';
import {
  mdiAccountOutline, mdiAccountTie, mdiDotsVertical, mdiRobotOutline,
} from '@mdi/js';
import { computed } from '@vue/composition-api';

export default {
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props) {
    const statisticsData = computed(() => [
      {
        title: '用户数',
        total: formatNumber(props.options.user_num || 0),
      },
      {
        title: '应用数',
        total: formatNumber(props.options.app_num || 0),
      },
      {
        title: '应用数/每用户',
        total: formatNumber(props.options.enterprise_num || 0),
      },
    ])

    const resolveStatisticsIconVariation = data => {
      if (data === '企业数') return { icon: mdiAccountTie, color: 'primary' }
      if (data === '用户数') return { icon: mdiAccountOutline, color: 'success' }
      if (data === '应用数') return { icon: mdiRobotOutline, color: 'warning' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      props,
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
