<template>
  <v-card>
    <v-card-title class="align-start">
      <span>知识库内容数</span>
      <v-spacer></v-spacer>

      <!-- <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn> -->
    </v-card-title>

    <v-card-text class="d-flex align-center justify-space-between pb-1 pt-5">
      <div>
        <p class="text-5xl font-weight-semibold text--primary mb-2">
          {{ formatNumber(props.options.knowledge_num) }}
        </p>
        <span class="text-base"></span>
      </div>

      <!-- <div class="chart-wrapper">
        <vue-apex-charts
          :options="chartOptions"
          :series="series"
        ></vue-apex-charts>
      </div> -->
    </v-card-text>

    <v-card-text class="mt-2">
      <div
        v-for="(data,index) in websiteStatistics"
        :key="data.title"
        :class="`statistics-table d-flex align-center pt-3 ${index > 0 ? 'mt-3':''}`"
      >
        <span :class="`badge-sm ${data.color}`"></span>
        <span class="text--primary ms-2">{{ data.title }}</span>

        <v-spacer></v-spacer>

        <span class="font-weight-semibold text--primary me-6">{{ data.traffic }}</span>
        <span
          class="font-weight-semibold text--primary me-1"
          style="display: inline-block;width: 80px;text-align:right;"
        >{{ data.percentage }}</span>
        <!-- <v-icon
          size="20"
          :color="data.percentage.charAt(0) === '+' ? 'success' :'error'"
        >
          {{ data.percentage.charAt(0) === '+' ? icons.mdiChevronUp:icons.mdiChevronDown }}
        </v-icon> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiChevronDown, mdiChevronUp, mdiDotsVertical } from '@mdi/js';
import { computed } from '@vue/composition-api';
import VueApexCharts from 'vue-apexcharts';

import { formatFileSize, formatNumber, getVuetify } from '@core/utils';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    options: {
      type: Object,
      required: true,
      default() {
        return {
          enterprise_num: '', //  企业数
          user_num: '', // 用户数
          app_num: '', // 应用数
          document_num: '', // 文档数
          faq_num: '', // faq 文件数
          link_num: '', // 链接数
          knowledge_num: '', // 知识库内容数
          document_total_size: 0, // 文档总大小，单位字节
          faq_total_size: 0, // faq文件总大小，单位字节
        }
      },
    },
  },
  setup(props) {
    const $vuetify = getVuetify()

    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
      },
      colors: [$vuetify.theme.currentTheme.primary],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          startingShape: 'rounded',
          endingShape: 'rounded',
          borderRadius: 4,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    }

    const websiteStatistics = computed(() => [
      {
        title: '文档数',
        color: 'success',
        traffic: formatNumber(props.options.document_num),
        percentage: formatFileSize(props.options.document_total_size),
      },
      {
        title: 'FAQ文件数',
        color: 'primary',
        traffic: formatNumber(props.options.faq_num),
        percentage: formatFileSize(props.options.faq_total_size),
      },
      {
        title: '链接数',
        color: 'warning',
        traffic: formatNumber(props.options.link_num),
        percentage: '',
      },
    ])

    return {
      formatNumber,
      props,
      chartOptions,
      websiteStatistics,
      series: [
        {
          name: '2020',
          data: [50, 40, 110, 80, 40, 60, 40],
        },
      ],
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
