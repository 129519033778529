<template>
  <v-card>
    <v-card-title class="align-start">
      <span>各渠道出话次数</span>
      <v-spacer></v-spacer>

      <!-- <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn> -->
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <vue-apex-charts
            id="chart-sales-overview"
            :options="chartOptions"
            :series="chartData"
            class="h-full d-flex align-center"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center"
        >
          <div class="d-flex align-center">
            <v-avatar
              class="v-avatar-light-bg primary--text"
              rounded
              size="40"
            >
              <v-icon
                size="30"
                color="primary"
              >
                {{ icons.mdiMessageOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-4 d-flex flex-column">
              <p class="text--primary mb-0 text-base">
                总出话次数
              </p>
              <span class="text--primary font-weight-semibold text-xl">{{ props.options.talk_num }}</span>
            </div>
          </div>

          <v-divider class="my-6"></v-divider>

          <table class="sales-overview-stats-table">
            <tr>
              <td>
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>FAQ出话</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{ props.options.faq_talk_num }}</span>
              </td>
              <td>
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>模型出话</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{ props.options.model_talk_num }}</span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { addAlpha, getVuetify } from '@core/utils'
import { mdiDotsVertical, mdiMessageOutline } from '@mdi/js'
import { computed } from '@vue/composition-api'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
    options: {
      type: Object,
      required: true,
      default() {
        return {
          talk_num: 0, // 出话次数
          faq_talk_num: 0, // 各渠道出话数：faq次数
          model_talk_num: 0, // 各渠道出话数：model次数
          token_total: 0, // token总数
          model_tokens_list: [
            // {
            //     "model_name": "Minimax", 模型名称
            //     "tokens": "71737", token数
            //     "model_id": "minimax"
            // },
          ],
        }
      },
    },
  },
  setup(props) {
    const $vuetify = getVuetify()

    const chartOptions = computed(() => ({
      labels: ['FAQ出话', '模型出话'],
      colors: [
        $vuetify.theme.currentTheme.primary,

        addAlpha($vuetify.theme.currentTheme.primary, 0.5),
        addAlpha($vuetify.theme.currentTheme.primary, 0.3),
        addAlpha($vuetify.theme.currentTheme.secondary, 0.1),
      ],
      chart: {
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,

                offsetY: -15,

                // formatter(value) {
                //   return `${value}k`
                // },
              },
              total: {
                show: true,
                label: '出话次数',
                color: 'rgba(94, 86, 105, 0.68)',

                formatter(value) {
                  return props.options.talk_num
                },
              },
            },
          },
        },
      },
    }))
    const chartData = computed(() => ([+props.options.faq_talk_num, +props.options.model_talk_num]))

    // const chartData = [123, 123]

    return {
      chartOptions,
      props,
      chartData,
      icons: {
        mdiDotsVertical,
        mdiMessageOutline,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}
</style>
