<template>
  <v-card>
    <v-card-title class="align-start">
      <div class="w-full d-flex align-center mb-2" style="justify-content: space-between;">
        {{ title }}
        <a
            href="javascript:void(0)"
            class="text-xs text-decoration-none"
            @click="toMore"
          >查看更多</a>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn> -->
    </v-card-title>
    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        height="250"
        :options="chartOptions"
        :series="chartData"
      ></vue-apex-charts>

      <!-- Stats -->
      <div class="d-flex justify-space-around flex-wrap mt-5">
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCurrencyUsd, mdiDotsVertical, mdiTrendingUp } from '@mdi/js';
import { computed } from '@vue/composition-api';

import { getVuetify, useRouter } from '@core/utils';

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: true,
      default: () => ({
        data: [],
      }),
    },
  },
  setup(props) {
    const $vuetify = getVuetify()

    const { router } = useRouter()
    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '60%',
          distributed: true,
          borderRadius: 4,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true, // 启用数据标签
        formatter(a, b) {
          return `${props.options.data[b.dataPointIndex].label.includes('@emotibot.com') ? props.options.data[b.dataPointIndex].label.slice(0, -13) : props.options.data[b.dataPointIndex].label}(${a})` // 在柱子内部显示数值
        },
        style: {
          fontSize: '12px', // 自定义字体大小
          color: '#fff', // 字体颜色
          align: 'end',
        },
        offsetX: 100,
      },
      xaxis: {
        categories: props.options.data.length ? props.options.data.map(item => item.label) : ['', '', '', '', '', '', '', '', '', ''],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            fontSize: '14px',
            colors: '#000',
          },
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          left: -10,
          right: -10,
        },
      },
    }

    const title = computed(() => props.title)
    const chartData = computed(() => {
      console.log('12333', props)

      return [
        {
          data: props.options.data.map(item => item.value),
        },
      ]
    })


    const toMore = () => {
        router.push({
          path: '/manage/user/list',
          query: {
            sortBy: title.value === 'Tokens使用排行榜' ? 'totalTokens' : 'app_count'
          }
        })
    }
    return {
      title,
      chartOptions,
      chartData,
      toMore,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
